<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_WINE_PREFERENCE')" type="back" :settings="false" :isBack="showBack" />
    </template>

    <div class="settingsinput-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.WineSweetnessId"
            name="WineSweetness"
            :required="validationRules?.WineSweetnessId?.required"
            :minlength="validationRules?.WineSweetnessId?.minimumLength"
            :maxlength="validationRules?.WineSweetnessId?.maximumLength"
            :label="$t.getTranslation('LBL_WINE_SWEETNESS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="wineSweetnessSelection && wineSweetnessSelection.length > 0">
              <option v-for="sweetness in wineSweetnessSelection" :key="'sweetness_' + sweetness.WineSweetnessId" :value="sweetness.WineSweetnessId">{{ sweetness.Name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.WineAcidityId"
            name="WineAcidity"
            :required="validationRules?.WineAcidityId?.required"
            :minlength="validationRules?.WineAcidityId?.minimumLength"
            :maxlength="validationRules?.WineAcidityId?.maximumLength"
            :label="$t.getTranslation('LBL_WINE_ACIDITY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="wineAciditySelection && wineAciditySelection.length > 0">
              <option v-for="acidity in wineAciditySelection" :key="'wa_' + acidity.Name" :value="acidity.WineAcidityId">{{ acidity.Name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.WineBodyId"
            name="WineBody"
            :required="validationRules?.WineBodyId?.required"
            :minlength="validationRules?.WineBodyId?.minimumLength"
            :maxlength="validationRules?.WineBodyId?.maximumLength"
            :label="$t.getTranslation('LBL_WINE_BODY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            date
          >
            <template v-if="wineBodySelection && wineBodySelection.length > 0">
              <option v-for="body in wineBodySelection" :key="'wb_' + body.Name" :value="body.WineBodyId">{{ body.Name }}</option>
            </template>
          </f7-list-input>

          <f7-list-item divider>{{ $t.getTranslation("LBL_PRICE_RANGE") }}</f7-list-item>

          <f7-list-input v-model:value="formData.WinePriceId" name="WinePrice" :label="$t.getTranslation('LBL_PRICE_RANGE')" :placeholder="$t.getTranslation('LBL_TYPE_HERE')" floating-label outline type="select">
            <template v-if="winePriceSelection && winePriceSelection.length > 0">
              <option v-for="price in winePriceSelection" :key="'wb_' + price.WinePriceId" :value="price.WinePriceId">{{ price.Name }}</option>
            </template>
          </f7-list-input>

          <f7-list-item>
            <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
              {{ $t.getTranslation("LBL_UPDATE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from "vue";
import { $HTTP } from "@/utils/axios";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "SettingsWinePreferencePage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const isButtonProcessing = ref(false);
    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const userInfo = computed(() => store.getters["user/getData"]);
    const wineSweetnessSelection = ref([]);
    const wineAciditySelection = ref([]);
    const wineBodySelection = ref([]);
    const winePriceSelection = ref([]);
    const showBack = ref(true);

    if (userInfo.value?.IsWechatSteps) {
      showBack.value = false;
    }

    const formData = reactive({
      WineSweetnessId: "",
      WineAcidityId: "",
      WineBodyId: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }
    onMounted(async () => {
      if (userInfo.value.UserWinePreference && userInfo.value.UserWinePreference.UserWinePreferenceId) {
        formData["WineSweetnessId"] = userInfo.value.UserWinePreference.WineSweetnessId;
        formData["WineAcidityId"] = userInfo.value.UserWinePreference.WineAcidityId;
        formData["WineBodyId"] = userInfo.value.UserWinePreference.WineBodyId;
        formData["WinePriceFrom"] = userInfo.value.UserWinePreference.WinePriceFrom;
        formData["WinePriceTo"] = userInfo.value.UserWinePreference.WinePriceTo;
        formData["WinePriceId"] = userInfo.value.UserWinePreference.WinePriceId;
        formData.UserWinePreferenceId = userInfo.value.UserWinePreference.UserWinePreferenceId;
      }

      await getWinePreference();
    });

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const getWinePreference = async () => {
      let res = await $HTTP.get(`/mobile/config/wine?LanguageCode=${currentLanguageCode.value}`);
      if (res.data.data) {
        res = res.data.data;
      }

      formData.PriceRange = [0, 5000];
      formData.UserKey = userInfo.value.UserKey;
      wineSweetnessSelection.value = res.WineSweetness;
      wineAciditySelection.value = res.WineAcidity;
      wineBodySelection.value = res.WineBody;
      winePriceSelection.value = res.WinePrice;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const next = async () => {
      //isButtonProcessing.value = true;
      if (formData.WinePriceFrom && !helpers.isNumeric(formData.WinePriceFrom)) {
        throw new Error($t.getTranslation("ERR_WINE_PRICE_SELECTION"));
      }

      if (formData.WinePriceTo && !helpers.isNumeric(formData.WinePriceTo)) {
        throw new Error($t.getTranslation("ERR_WINE_PRICE_SELECTION"));
      }

      if (formData.WinePriceTo && formData.WinePriceFrom && parseFloat(formData.WinePriceFrom) > parseFloat(formData.WinePriceTo)) {
        throw new Error($t.getTranslation("ERR_WINE_PRICE_SELECTION"));
      }
      let ret = await post("/user/wine", formData);
      if (ret) {
        //isButtonProcessing = false;
        helpers.createNotification({
          type: "info",
          title: $t.getTranslation("LBL_INFO"),
          message: $t.getTranslation("LBL_INFO_SUCCESS_WINE_PREFERENCE_UPDATE"),
        });
        if (userInfo.value?.IsWechatSteps) {
          store.dispatch("user/setData", { IsWechatSteps: false });
        }
        store.dispatch("user/fetchData", { force: true });
        props.f7router.back({ force: true });
        return;
      }
    };

    return {
      formData,
      wineSweetnessSelection,
      wineAciditySelection,
      wineBodySelection,
      winePriceSelection,
      next,
      showBack,
    };
  },
});
</script>
